/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

import focusLock from 'dom-focus-lock';
import ResponsiveAutoHeight from 'responsive-auto-height';

// Breakpoints
const mediaQueryLaptop = window.matchMedia('(min-width: 1024px)');

// Calculate 
var timeout = false,
	timeoutDelay = 250;

function calculateSizes() {
	document.documentElement.style.setProperty('--sw', `${window.innerWidth - document.documentElement.clientWidth}px`);
	document.documentElement.style.setProperty('--hh', `${document.documentElement.clientHeight + 3}px`);
}

calculateSizes();

window.addEventListener('resize', function () {
	clearTimeout(timeout);
	timeout = setTimeout(calculateSizes, timeoutDelay);
});

// Headeroom
import Headroom from 'headroom.js';
var headroom = new Headroom(document.body);
headroom.init();

// Equellize height
if (document.querySelectorAll('.match-height').length) {
	new ResponsiveAutoHeight('.match-height');
}

// Animate On Scroll
var toAnimate = document.querySelectorAll('.has-animation');
if (toAnimate.length) {

	var handleIntersection = function (entries) {

		entries.map((entry) => {
			if (entry.isIntersecting) {
				entry.target.classList.add("animate");
				observer.unobserve(entry.target);
			}
		});
	};

	const options = {
		threshold: 0.1
	};

	const observer = new IntersectionObserver(handleIntersection, options);

	toAnimate.forEach(el => observer.observe(el));
}

// Lightgallery
async function loadLightGallery() {
	await import(/* webpackChunkName: "lightgallery" */ 'lightgallery.js');
	await import(/* webpackChunkName: "lightgallery" */ 'lg-video.js');

	lightGallery(document.getElementsByTagName("body")[0], {
		selector: '[rel=modal]',
		download: false,
		thumbnail: false,
		fullScreen: true,
		actualSize: false,
		hash: false,
		videoMaxWidth: '2560px'
	});
}

var elementsWithModal = document.querySelectorAll('[rel=modal]');
if (elementsWithModal.length) {
	loadLightGallery();
}

// Descriptions
var descriptions = document.querySelectorAll('.description');
if (descriptions.length) {

	descriptions.forEach(el => {
		const descriptionButton = el.querySelector('.button');
		const descriptionMessage = el.querySelector('.description__message');
		const descriptionClose = el.querySelector('.description__close');

		descriptionButton.addEventListener("click", function (e) {
			descriptionMessage.classList.add('is-visible');
			e.preventDefault();
		});

		descriptionClose.addEventListener("click", function (e) {
			descriptionMessage.classList.remove('is-visible');
			e.preventDefault();
		});
	});
}

// Close navigation on backdrop click or escape key
const navigationToggler = document.getElementById('side-pop-up'), navigation = document.querySelector('.side-pop-up'), navigationContainer = document.querySelector('.side-pop-up__container');
if (navigationToggler && navigation && navigationContainer) {

	const toggle = checked => {
		navigationToggler.checked = checked;
		navigation.setAttribute('aria-expanded', checked);

		if (checked) {
			focusLock.on(navigationContainer);
			document.documentElement.classList.add('has-no-overflow');
		} else {
			focusLock.off(navigationContainer);
			document.documentElement.classList.remove('has-no-overflow');
		}
	};

	navigation.addEventListener('click', e => {
		if (!navigationContainer.contains(e.target)) {
			toggle(false);
		}
	});

	document.addEventListener('keydown', e => {
		if (e.keyCode === 27 && navigationToggler.checked) {
			toggle(false);
		}
	});

	// Keep aria-expanded in-sync with checked state
	navigationToggler.addEventListener('change', () => {
		toggle(navigationToggler.checked);
	});

	toggle(navigationToggler.checked);
}

// Slider
var sliders = document.querySelectorAll('.content-block.is-slider-block');
if (sliders.length) {
	(async () => {
		const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;

		sliders.forEach(el => {
			var swiperPagination = document.createElement('div');
			swiperPagination.setAttribute('class', 'swiper-pagination');
			el.appendChild(swiperPagination);

			var slider = new Swiper(el.querySelector('.swiper-container'), {
				watchOverflow: true,
				loop: true,
				autoplay: {
					delay: 2500,
					disableOnInteraction: true
				},
				speed: 1500,
				slidesPerView: 'auto',
				centeredSlides: true,
				spaceBetween: 20,
				keyboard: {
					enabled: true
				},
				pagination: {
					el: el.querySelector('.swiper-pagination'),
					type: 'bullets',
					clickable: true
				},
				breakpoints: {
					768: {
						spaceBetween: 0
					}
				}
			});
		});
	})();
}

// Fader
var faderSliders = document.querySelectorAll('.content-block.is-fader-block');
if (faderSliders.length) {
	(async () => {
		const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;

		faderSliders.forEach(el => {
			var swiperPagination = document.createElement('div');
			swiperPagination.setAttribute('class', 'swiper-pagination');
			el.querySelector('.swiper-container').appendChild(swiperPagination);

			new Swiper(el.querySelector('.swiper-container'), {
				effect: "fade",
				watchOverflow: true,
				autoplay: {
					delay: 3500,
					disableOnInteraction: true
				},
				speed: 1000,
				slidesPerView: 1,
				keyboard: {
					enabled: true
				},
				pagination: {
					el: el.querySelector('.swiper-pagination'),
					type: 'bullets',
					clickable: true,
					renderBullet: function (index, className) {
						return '<span class="' + className + '">' + (el.querySelectorAll('.swiper-slide')[index].getAttribute('data-bullet-name')) + '</span>';
					}
				}
			});
		});
	})();
}

// Paged images
async function loadWaypoints(overviews) {
	const InfiniteScroll = (await import(/* webpackChunkName: "waypoints" */ 'infinite-scroll')).default;

	overviews.forEach(el => {
		var container = el.querySelector('.columns'),
			button = el.querySelector('.page-link'),
			pagingContainer = el.querySelector('.pagination');

		button.addEventListener('click', function (e) {

			var infScroll = new InfiniteScroll(el, {
				// options
				path: '.page-link',
				append: false,
				history: false
			});

			infScroll.on('load', function (response, path) {
				const delay = 40;
				var i = 0;

				response.querySelectorAll('.column').forEach(child => {
					container.appendChild(child);
					setTimeout(function () { child.querySelector('.overview-item').classList.add('animate'); }, delay * i);
					i++;
				});
			});

			infScroll.on('last', function (response, path) {
				pagingContainer.remove();
			});

			infScroll.loadNextPage();

			e.preventDefault();
		});
	});
}

var mediaOverviews = document.querySelectorAll('.is-media-oveview');
if (mediaOverviews.length) {
	loadWaypoints(mediaOverviews);
}

// Hero Video
var hero = document.querySelector('.hero');
if (hero) {
	const mobileVideo = hero.querySelector('.video.is-mobile-version');
	const desktopVideo = hero.querySelector('.video.is-desktop-version');

	var heroResize = function () {
		if (desktopVideo.src.length > 0) {
			if (mediaQueryLaptop.matches) {
				desktopVideo.src = desktopVideo.querySelector('source').dataset.src;
				desktopVideo.load();
			}
			else {
				mobileVideo.src = mobileVideo.querySelector('source').dataset.src;
				mobileVideo.load();
			}
		}
	};

	var heroTimeout = null;
	var heroTimeoutDelay = 250;
	window.addEventListener('resize', function () {
		clearTimeout(heroTimeout);
		heroTimeout = setTimeout(heroResize(), heroTimeoutDelay);
	});

	hero.onclick = function () {
		var videoToChange = null;

		if (mediaQueryLaptop.matches) {
			var videoSources = desktopVideo.querySelectorAll('source');

			videoSources.forEach(videoSource => {
				videoSource.src = videoSource.dataset.src;
			});

			videoToChange = desktopVideo;
		}
		else {
			var videoSources = mobileVideo.querySelectorAll('source');

			videoSources.forEach(videoSource => {
				videoSource.src = videoSource.dataset.src;
			});

			videoToChange = mobileVideo;
		}

		videoToChange.classList.add('is-active');

		if (!videoToChange.classList.contains('is-playing')) {
			videoToChange.load();
			videoToChange.play();
			videoToChange.classList.add('is-playing');
			videoToChange.classList.remove('is-paused');
		}
		else {
			videoToChange.pause();
			videoToChange.classList.add('is-paused');
			videoToChange.classList.remove('is-playing');
		}
	};
}

// Header video
var header = document.querySelector('.header');
if (header) {
	var headerVideo = header.querySelector('.video');

	if (headerVideo) {
		header.onclick = function () {

			if (!header.classList.contains('is-playing')) {
				header.classList.add('is-playing');
				headerVideo.classList.remove('is-paused')
				headerVideo.classList.add('is-active')
				headerVideo.classList.add('is-playing')
				headerVideo.play();
				headerVideo.style.opacity = 1;
			}
			else {
				header.classList.remove('is-playing');
				headerVideo.classList.remove('is-playing')
				headerVideo.classList.add('is-paused')
				headerVideo.pause();
			}
		}
	}
}

// Lazy load case videos
var videosWithMouseOver = document.querySelectorAll('.video-with-mouseover');
if (videosWithMouseOver) {

	videosWithMouseOver.forEach(videoWithMouseOver => {
		videoWithMouseOver.addEventListener('mouseover', function () {

			if (!this.classList.contains('video-loaded')) {
				var videoSources = this.querySelectorAll('source');

				videoSources.forEach(videoSource => {
					videoSource.src = videoSource.dataset.src;
				});

				this.load();
				this.classList.add('video-loaded');
			}

			this.play();
		});

		videoWithMouseOver.addEventListener('mouseleave', function () {
			this.pause();
		});
	});
}
